<template>
    <div v-prefix class="#share-toggle relative">
        <button 
            v-prefix 
            @click="toggle_share_options()" 
            :class="{ 
                'is-toggled': show_share_options, 
            }" 
            class="#share-toggle__toggle sidearm-galleries-btn --basic mr-2 type-base" 
            type="button" 
            aria-label="Toggle gallery share options"
        >
            <icon>sf-share</icon>
        </button>

        <ul v-prefix :class="{ 'is-toggled': show_share_options }" class="#share-toggle__dropdown reset bg-white text-no-wrap">
            <li class="border-light-b">
                <button @click="open_share_window('facebook')" type="button" class="sidearm-galleries-btn" aria-label="Share gallery on Facebook">
                    <icon size="1.125rem" class="text-facebook mr-1">sf-facebook</icon>
                    <span>Facebook</span>
                </button>
            </li>
            <li class="border-light-b">
                <button @click="open_share_window('twitter')" type="button" class="sidearm-galleries-btn" aria-label="Share gallery on Twitter">
                    <icon size="1.125rem" class="text-twitter mr-1">sf-twitter</icon>
                    <span>Twitter</span>
                </button>
            </li>
            <li class="border-light-b">
                <a :href="email_share_url" type="button" class="sidearm-galleries-btn" aria-label="Email link to gallery">
                    <icon size="1.125rem" class="text-email mr-1">sf-email</icon>
                    <span>Email</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            gallery: { type: Object, required: true }
        },

        data: () => ({
            show_share_options: false,

            facebook_share_url: '',
            twitter_share_url: '',
            email_share_url: '',
        }),

        watch: {
            gallery: {
                handler(newval, oldval) {
                    if (newval) {
                        this.set_share_links();
                    }
                },
                deep: true,
            }
        },

        methods: {
            toggle_share_options(value) {
                if (value !== undefined) {
                    this.show_share_options = !!value;
                }
                else {
                    this.show_share_options = !this.show_share_options;
                }
            },

            open_share_window(platform) {
                const data_key = `${platform}_share_url`;
                const url = this[data_key];
                console.log(url);
                const options = 'toolbar=0,status=0,resizable=1,width=626,height=436';

                if (url) {
                    window.open(url, 'sharer', options);
                }
            },

            set_share_links() {
                const title = encodeURIComponent(this.gallery.title);
                const url = window.location.href;

                this.facebook_share_url = `http://www.facebook.com/sharer.php?u=${url}&amp;p[title]=Gallery:%20${title}`;
                this.twitter_share_url = `http://twitter.com/share?text=Gallery:%20${title}&amp;url=${url}`;
                this.email_share_url = `mailto:?body=${url}`;
            }
        },

        created() {
            if (this.gallery) {
                this.set_share_links();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__share-toggle {
        &__toggle {
            padding-left: $spacer*1.5;
            padding-right: $spacer*1.5;
        }

        &__dropdown {
            @include hide();

            &.is-toggled {
                @include unhide();

                box-shadow: 0px 1px $spacer rgba($color-black, 0.08);
                left: 0;
                position: absolute;
                top: 100%;
            }

            button, a {
                color: $color-black;
                display: block;
                font-size: 0.875rem;
                font-weight: normal;
                padding: $spacer;
                text-align: left;
                width: 100%;

                &:hover {
                    background-color: $color-primary;
                    color: $text-on-primary;

                    > span {
                        color: $text-on-primary;
                    }
                }
            }
        }
    }
</style>