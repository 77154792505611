<template>
    <header v-prefix class="#gallery-header bg-white py-2">
        <div :class="{ 'full-width': template_options.full_width }" class="row flex flex-column flex-align-start large-flex-row large-flex-align-center">
            <div class="flex flex-align-center">
                <button @click="$router.go(-1)" class="sidearm-galleries-btn --basic mr-1 type-base">
                    <icon class="mr-1">sf-arrows-thick-left</icon>
                    <span>Return</span>
                </button>

                <social-share :gallery="gallery" />
            </div>

            <div class="flex flex-item-1 flex-column large-flex-row flex-align-start large-flex-align-center">
                <div v-if="gallery" v-prefix class="#gallery-header__details flex-item-1">
                    <div>{{ gallery.title }}</div>
                    <div>
                        <span class="text-bold">{{ gallery.sport.abbreviation }}</span>
                        &bull;
                        <span>{{ moment(gallery.date).format('MM.DD.YYYY') }}</span>
                    </div>
                </div>

                <span v-if="gallery" v-prefix class="#gallery-header__image-count sidearm-galleries-btn --primary --rounded">{{ gallery.image_count }} Photos</span>
            </div>
        </div>
    </header>
</template>

<script>
    import SocialShare from '@/components/SocialShare'
    import { mapState } from 'vuex'

    export default {
        props: {
            gallery: {
                type: Object,
                required: true,
                default: () => ({})
            }
        },

        components: {
            SocialShare
        },

        data: () => ({
            social_share_open: false,
        }),

        computed: {
            ...mapState([
                'template_options',
            ]),

            gallery_url() {
                const params = this.$route.params;

                return `${window.location.origin}/${params.sport_slug}/${params.gallery_slug}/${params.gallery_id}`;
            },
        },

        methods: {
            toggle_social_share(value) {
                console.log(value);
                if (value !== undefined) {
                    this.social_share_open = !!value;
                }
                else {
                    this.social_share_open = !this.social_share_open;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__gallery-header {
        position: sticky;
        top: 84px;
        z-index: 90;

        &__share-toggle {
            padding-left: $spacer*1.5;
            padding-right: $spacer*1.5;
        }

        &__share-popover {
            @include hide();

            &.is-toggled {
                @include unhide();

                box-shadow: 0px 1px $spacer rgba($color-black, 0.08);
                left: 0;
                position: absolute;
                top: 100%;
            }
        }

        @media (max-width: #{$breakpoint-large-down}) {
            position: relative;
            top: 0;

            &__details {
                margin: $spacer 0;
            }
        }
    }
</style>