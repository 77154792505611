<template>
    <div v-prefix v-if="gallery" class="#gallery3 flex flex-wrap">
        <template v-for="(image, image_index) in gallery.images" >
            <div :key="image.id" :class="image.layout_classes" class="pa-1 x-small-12 medium-6 large-4 x-large-3">
                <router-link 
                    v-prefix
                    :to="{ 
                        name: 'image', 
                        params: { 
                            ...$route.params, 
                            image_caption_slug: image.caption_slug.substring(0,30) || `image-${image_index+1}`, 
                            image_id: image.id 
                        }
                    }" 
                    class="#gallery3__list-item-link block w-100 h-100 flex flex-column border-light-a"
                >
                    <aspect-ratio v-prefix :ratio="9/16" class="#gallery3__list-item-image-container relative overflow-hidden flex-item-auto">
                        <img v-lazy="image.medium" class="fade-on-lazy-load object-fit-cover object-position-center object-fit-positioning" />
                    </aspect-ratio>

                    <div class="flex-item-1 pa-2">
                        <div v-prefix class="#gallery3__list-item-title">{{ gallery.title }}</div>

                        <div class="sidearm-galleries-btn --primary --rounded mt-1">View Photo</div>
                    </div>
                </router-link>
            </div>

            <div v-if="image_index > 0 && (image_index+1) % 12 === 0" :key="`gallery_image_ad_${image_index}`" class="x-small-12 columns pa-1">
                <dfp-ad v-if="repeated_ad_data || display_dummy_ads" :location="repeated_ad_data.location" name="galleries-repeated-1" />
            </div>
        </template>
    </div>
</template>

<script>
    import DfpAd from '@/components/Ads/DfpAd'
    import { mapState } from 'vuex'

    export default {
        props: {
            gallery: {
                type: Object,
                required: true,
                default: () => ({
                    id: 0,
                    images: [],
                })
            }
        },

        components: {
            DfpAd,
        },

        computed: {
            ...mapState([
                'repeated_ad_data',
                'display_dummy_ads',
            ]),
        },
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__gallery3 {
        margin: 0 $spacer*-1;

        &__list-item-image-container {
            background-color: $stroke-on-light;
        }
        
        &__list-item-link {
            text-decoration: none;

            &:hover {
                img {
                    transform: scale(1.2);
                }

                .#{$prefix}__gallery3__list-item-title {
                    text-decoration: underline;
                }
            }

            img {
                transition: transform .35s, opacity .35s .35s;
            }
        }

        &__list-item-title {
            text-decoration: none;
        }
    }
</style>