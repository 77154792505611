<template>
    <div v-prefix :key="render_key" ref="container" class="#gallery4 flex flex-wrap">
        <template v-for="(image, image_index) in images_with_widths">
            <template v-if="image !== null">
                <div :key="image.id" :style="{
                    width: image.layout_width ? `${image.layout_width}px` : '0px',
                    height: image.layout_width ? `${image.layout_width / image.aspect_ratio}px` : '100px',
                    margin: '12px',
                }">
                    <router-link 
                        v-prefix
                        :to="{ 
                            name: 'image', 
                            params: { 
                                ...$route.params, 
                                image_caption_slug: image.caption_slug.substring(0,30) || `image-${image_index+1}`, 
                                image_id: image.id 
                            }
                        }" 
                        class="#gallery4__list-item-link block relative overflow-hidden h-100"
                    >
                        <img v-prefix v-lazy="image.large" class="#gallery4__list-item-image fade-on-lazy-load object-fit-cover object-position-center object-fit-positioning" />

                        <span v-prefix class="#gallery4__list-item-details pa-1 hide-on-small-down text-no-wrap text-decoration-none">View Photo</span>
                    </router-link>
                </div>
            </template>

            <div v-if="image === null" :key="`gallery_image_ad_${image_index}`" class="x-small-12 columns pa-1">
                <dfp-ad v-if="repeated_ad_data || display_dummy_ads" :location="repeated_ad_data.location" name="galleries-repeated-1" />
            </div>
        </template>
    </div>
</template>

<script>
    import DfpAd from '@/components/Ads/DfpAd'
    import { mapState } from 'vuex'

    export default {
        props: {
            gallery: {
                type: Object,
                required: true,
                default: () => ({
                    id: 0,
                    images: [],
                })
            }
        },

        components: {
            DfpAd,
        },

        data: () => ({
            images_with_widths: [],

            render_key: Date.now(),
        }),

        computed: {
            ...mapState([
                'repeated_ad_data',
                'display_dummy_ads',
            ]),
        },

        watch: {
            'gallery.id'() {
                this.images_with_widths = this.gallery.images.slice();

                this.$nextTick(() => {
                    this.lay_out_grid();
                })
            },
        },

        methods: {
            lay_out_grid() {
                if (!this.gallery || !this.gallery.images) {
                    return;
                }

                var row_of_images = [];
                var current_row = 1;
                var image_positions = [];
                var aspect_ratio_of_current_row_images = 0;
                var images = this.gallery.images.slice();

                const $container = this.$refs.container;
                const computed_styles = getComputedStyle($container);
                const row_width = $container.clientWidth - parseFloat(computed_styles.paddingLeft) - parseFloat(computed_styles.paddingRight) - 1;
                const ideal_row_height = window.innerHeight / 3 < 250 ? 200 : window.innerHeight / 3;
                const gutter = 24;
                const row_aspect_ratio = (row_width/ideal_row_height).toFixed(2);
                const indices_to_add_ads_at = [];

                images.forEach((image, index) => {
                    image_positions.push(index);
                    row_of_images.push(image);

                    aspect_ratio_of_current_row_images += image.aspect_ratio;

                    if (row_width / aspect_ratio_of_current_row_images < ideal_row_height) {
                        let total_aspect_ratio_of_row = row_of_images.reduce((total, i) => total + i.aspect_ratio, 0);

                        image_positions.forEach(position => {
                            let gutters_as_percentage_of_row_width = (image_positions.length * gutter) / row_width;
                            let image_width_in_pixels = (images[position].aspect_ratio/total_aspect_ratio_of_row) * row_width;
                            let adjusted_image_width = image_width_in_pixels * (1-gutters_as_percentage_of_row_width);


                            images[position].layout_width = Math.floor(adjusted_image_width.toFixed(3));
                        });

                        image_positions = [];
                        row_of_images = [];
                        aspect_ratio_of_current_row_images = 0;

                        if (current_row % 4 === 0) {
                            indices_to_add_ads_at.push(index+1);
                        }

                        ++current_row;                        
                    }

                    if (index === images.length - 1) {
                        let total_aspect_ratio_of_row = row_of_images.reduce((total, image) => total + image.aspect_ratio, 0);

                        if (total_aspect_ratio_of_row < row_aspect_ratio) {
                            total_aspect_ratio_of_row = row_aspect_ratio;
                        }

                        image_positions.forEach(position => {
                            let gutters_as_percentage_of_row_width = (image_positions.length * gutter) / row_width;
                            let image_width_in_pixels = (images[position].aspect_ratio/total_aspect_ratio_of_row) * row_width;
                            let adjusted_image_width = image_width_in_pixels * (1-gutters_as_percentage_of_row_width);

                            images[position].layout_width = Math.floor(adjusted_image_width.toFixed(3));
                        });
                    }
                });

                indices_to_add_ads_at.forEach((index, offset) => {
                    images.splice(index+offset, 0, null);
                });

                this.images_with_widths = images.slice();

                this.render_key = Date.now();
            }
        },

        created() {
            if (this.gallery && this.gallery.images) {
                this.images_with_widths = this.gallery.images;
            }
        },

        mounted() {
            this.lay_out_grid();

            window.addEventListener('resize', this.lay_out_grid);
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.lay_out_grid, false);
        }
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__gallery4 {
        &__list-item-link {
            background-color: $stroke-on-light;

            &::before, &::after {
                content: '';
                display: block;
                left: 0;
                position: absolute;
                top: 0;
                transition: .35s;
                width: 100%;
                z-index: 1;
            }

            &::before {
                background-color: $color-primary;
                height: 100%;
                opacity: 0;
            }

            &::after {
                background-color: $color-secondary;
                height: $spacer/3;
                transform-origin: left;
                transform: scaleX(0);
            }

            &:hover {
                &::before {
                    opacity: 0.8;
                }

                &::after {
                    transform: scaleX(1);
                }

                .#{$prefix}__gallery4__list-item-details {
                    opacity: 1;
                    transform: translate(-50%, -50%);
                    transition-delay: .2s;
                }
            }
        }

        &__list-item-details {
            border: 1px solid $text-on-primary;
            color: $text-on-primary;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -75%);
            transition: .35s;
            z-index: 2;
        }
    }
</style>