<template>
    <div v-prefix v-if="gallery" class="#gallery6">
        <div v-prefix class="#gallery6__scroll-container overflow-hidden relative" ref="container">
            <div v-prefix class="#gallery6__scroll-track" :style="{ transform: `translateX(${track_offset}px)`}" ref="track">
                <div v-prefix class="#gallery6__row flex" ref="top_row">
                    <div 
                        v-prefix
                        v-for="image in gallery_images_top_row" 
                        :key="image.id" 
                        :style="{ 
                            width: `calc(((100vh - 108px - max(calc(3rem + 72px), calc(1.5rem + 38px))) / 2) * ${image.aspect_ratio})`,
                        }"
                        class="#gallery6__row-item flex-item-auto ma-1 relative overflow-hidden" 
                    >
                        <router-link 
                            v-prefix
                            :to="{ 
                                name: 'image', 
                                params: { 
                                    ...$route.params, 
                                    image_caption_slug: image.caption_slug.substring(0,30) || `image-${image.index+1}`, 
                                    image_id: image.id 
                                }
                            }" 
                            class="#gallery6__list-item-link block w-100 h-100"
                        >
                            <img v-lazy="image.medium" class="fade-on-lazy-load h-100" />

                            <span v-prefix class="#gallery6__list-item-details hide-on-small-down text-no-wrap">View Photo</span>
                        </router-link>
                    </div>
                </div>

                <div v-prefix class="#gallery6__row flex" ref="bottom_row">
                    <div 
                        v-prefix
                        v-for="image in gallery_images_bottom_row" 
                        :key="image.id" 
                        :style="{ 
                            width: `calc(((100vh - 108px - max(calc(3rem + 72px), calc(1.5rem + 38px))) / 2) * ${image.aspect_ratio})`,
                        }"
                        class="#gallery6__row-item flex-item-auto ma-1 relative overflow-hidden" 
                    >
                        <router-link 
                            v-prefix
                            :to="{ 
                                name: 'image', 
                                params: { 
                                    ...$route.params, 
                                    image_caption_slug: image.caption_slug || `image-${image.index+1}`, 
                                    image_id: image.id 
                                }
                            }" 
                            class="#gallery6__list-item-link block w-100 h-100"
                        >
                            <img v-lazy="image.medium" class="fade-on-lazy-load h-100" />

                            <span v-prefix class="#gallery6__list-item-details hide-on-small-down">View Photo</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import throttle from 'lodash-es/throttle'

    export default {
        props: {
            gallery: {
                type: Object,
                required: true,
                default: () => ({})
            }
        },

        data: () => ({
            gallery_images_top_row: [],

            gallery_images_bottom_row: [],

            track_offset: 0,

            top_row_width: 0,
            
            bottom_row_width: 0,

            end_of_row_reached: false,
        }),

        computed: {
            longest_row_width() {
                if (this.top_row_width > this.bottom_row_width) {
                    return this.top_row_width;
                }

                return this.bottom_row_width;
            }
        },

        watch: {
            gallery: {
                handler(newVal, oldVal) {
                    this.divide_images();
                },
                deep: true,
            },
        },

        methods: {
            divide_images() {
                if (!this.gallery || !this.gallery.images || !this.gallery.images.length) {
                    this.gallery_top_row = [];
                    this.gallery_bottom_row = [];
                    return;
                }

                var top_row_images = [];
                var bottom_row_images = [];
                let total_aspect_ratios_of_top_row = 0;
                let total_aspect_ratios_of_bottom_row = 0;

                this.gallery.images.forEach(image => {
                    if (total_aspect_ratios_of_top_row <= total_aspect_ratios_of_bottom_row) {
                        top_row_images.push(image);
                        total_aspect_ratios_of_top_row += image.aspect_ratio;
                    }
                    else {
                        bottom_row_images.push(image);
                        total_aspect_ratios_of_bottom_row += image.aspect_ratio;
                    }
                });

                this.gallery_images_top_row = top_row_images;
                this.gallery_images_bottom_row = bottom_row_images;

                this.$nextTick(() => {
                    const top_row_items = [].slice.call(this.$refs.top_row.querySelectorAll('div'));
                    const bottom_row_items = [].slice.call(this.$refs.bottom_row.querySelectorAll('div'));

                    this.top_row_width = top_row_items.reduce((total, i) => total + i.clientWidth + 24, 0);
                    this.bottom_row_width = top_row_items.reduce((total, i) => total + i.clientWidth + 24, 0);
                })
            },

            throttled_move_track: throttle(function(event) {
                if (window.matchMedia('(max-width: 768px)').matches) {
                    return;
                }

                if (this.$refs.track) {
                    const max_scroll_position = this.longest_row_width - this.$refs.container.clientWidth;
                    const scroll_direction = Math.sign(event.deltaY * -1);
        
                    let scroll_position = this.track_offset + (scroll_direction * 120);

                    if (scroll_position > 0 ){
                        scroll_position = 0;
                        this.end_of_row_reached = false;
                    }
                    else if (Math.abs(scroll_position) >= max_scroll_position) {
                        scroll_position = max_scroll_position * -1;
                        this.end_of_row_reached = true;
                    }
                    else {
                        this.end_of_row_reached = false;
                    }

                    this.track_offset = scroll_position;
                }
            }, 100),

            prevent_scroll_until_end_of_gallery() {
                if (window.matchMedia('(max-width: 768px)').matches) {
                    return;
                }

                if (!this.end_of_row_reached) {
                    window.scrollTo(0,0);
                }
            },
        },

        created() {
            if (this.gallery && this.gallery.images) {
                this.divide_images();
            }
        },

        mounted() {
            window.addEventListener('wheel', this.throttled_move_track);

            window.addEventListener('scroll', this.prevent_scroll_until_end_of_gallery);
        },

        beforeDestroy() {
            window.removeEventListener('scroll', this.prevent_scroll_until_end_of_gallery);
        }
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__gallery6 {
        margin-top: -$spacer;
        
        &__scroll-track {
            transition: .1s;
        }

        &__row {
            height: calc((100vh - 108px - max(calc(3rem + 2px + #{$spacer*2}), calc(1.5rem + 2px + #{$spacer*3}))) / 2);
        }

        &__row-item:first-child {
            margin-left: 0;
        }

        &__list-item-link {
            background-color: $stroke-on-light;
            
            &::before, &::after {
                content: '';
                display: block;
                left: 0;
                position: absolute;
                top: 0;
                transition: .35s;
                width: 100%;
                z-index: 1;
            }

            &::before {
                background-color: $color-primary;
                height: 100%;
                opacity: 0;
            }

            &::after {
                background-color: $color-secondary;
                height: $spacer/3;
                transform-origin: left;
                transform: scaleX(0);
            }

            &:hover {
                &::before {
                    opacity: 0.8;
                }

                &::after {
                    transform: scaleX(1);
                }

                .#{$prefix}__gallery6__list-item-details {
                    opacity: 1;
                    transform: translate(-50%, -50%);
                    transition-delay: .2s;
                }
            }
        }

        &__list-item-details {
            border: 1px solid $text-on-primary;
            color: $text-on-primary;
            left: 50%;
            opacity: 0;
            padding: $spacer/2 $spacer*2;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -75%);
            transition: .35s;
            z-index: 2;
        }

        @media (max-width: #{$breakpoint-large-down}) {
            &__scroll-container {
                overflow: auto !important;
            }
            
            &__scroll-track {
                transform: initial !important;
            }
        }
    }
</style>