var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],key:_vm.render_key,ref:"container",staticClass:"#gallery4 flex flex-wrap"},[_vm._l((_vm.images_with_widths),function(image,image_index){return [(image !== null)?[_c('div',{key:image.id,style:({
                width: image.layout_width ? ((image.layout_width) + "px") : '0px',
                height: image.layout_width ? ((image.layout_width / image.aspect_ratio) + "px") : '100px',
                margin: '12px',
            })},[_c('router-link',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#gallery4__list-item-link block relative overflow-hidden h-100",attrs:{"to":{ 
                        name: 'image', 
                        params: Object.assign({}, _vm.$route.params, 
                            {image_caption_slug: image.caption_slug.substring(0,30) || ("image-" + (image_index+1)), 
                            image_id: image.id})
                    }}},[_c('img',{directives:[{name:"prefix",rawName:"v-prefix"},{name:"lazy",rawName:"v-lazy",value:(image.large),expression:"image.large"}],staticClass:"#gallery4__list-item-image fade-on-lazy-load object-fit-cover object-position-center object-fit-positioning"}),_c('span',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#gallery4__list-item-details pa-1 hide-on-small-down text-no-wrap text-decoration-none"},[_vm._v("View Photo")])])],1)]:_vm._e(),(image === null)?_c('div',{key:("gallery_image_ad_" + image_index),staticClass:"x-small-12 columns pa-1"},[(_vm.repeated_ad_data || _vm.display_dummy_ads)?_c('dfp-ad',{attrs:{"location":_vm.repeated_ad_data.location,"name":"galleries-repeated-1"}}):_vm._e()],1):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }