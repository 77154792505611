<template>
    <div>
        <div v-if="gallery">
            <gallery-header :gallery="gallery" />

            <div :class="{ 'full-width': template_options.full_width }" class="row">
                <component :is="gallery_template" :gallery="gallery" />
            </div>
        </div>
    </div>
</template>

<script>
    import GalleryHeader from '@/components/GalleryHeader.vue'
    import GalleryTemplate1 from './Gallery1.vue'
    import GalleryTemplate2 from './Gallery2.vue'
    import GalleryTemplate3 from './Gallery3.vue'
    import GalleryTemplate4 from './Gallery4.vue'
    import GalleryTemplate5 from './Gallery5.vue'
    import GalleryTemplate6 from './Gallery6.vue'

    import api from '@/api'
    import { set_page_title } from '@/helpers'
    import { mapState } from 'vuex';

    export default {
        components: {
            GalleryHeader,
            'template_1': GalleryTemplate1,
            'template_2': GalleryTemplate2,
            'template_3': GalleryTemplate3,
            'template_4': GalleryTemplate4,
            'template_5': GalleryTemplate5,
            'template_6': GalleryTemplate6,
        },

        data: () => ({
            gallery_id: 0,

            gallery: null,
        }),

        computed: {
            ...mapState([
                'template_options'
            ]),

            gallery_template() {
                if (!this.gallery) {
                    return "";
                }

                if (this.gallery && this.gallery.template) {
                    return this.gallery.template;
                }

                return this.template_options.gallery_template;
            },
        },

        watch: {
            $route() {
                console.log('route changed');
            }
        },

        created() {
            this.gallery_id = this.$route.params.gallery_id;

            api.get_gallery(this.gallery_id)
                .then(gallery => {
                    set_page_title(gallery.title);
                    this.gallery = gallery;
                });
        }
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__gallery {

    }
</style>