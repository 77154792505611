var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.gallery)?_c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#gallery5"},[_c('packery',{staticClass:"w-100",attrs:{"sync":_vm.gallery.images}},_vm._l((_vm.gallery.images),function(image,image_index){return _c('div',{key:image.id,staticClass:"x-small-6 large-4 pa-1",class:{
                'x-large-3': _vm.template_options.full_width,
            }},[_c('router-link',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#gallery5__list-item-link block relative overflow-hidden",attrs:{"to":{ 
                    name: 'image', 
                    params: Object.assign({}, _vm.$route.params, 
                        {image_caption_slug: image.caption_slug.substring(0,30) || ("image-" + (image_index+1)), 
                        image_id: image.id})
                }}},[_c('aspect-ratio',{attrs:{"ratio":image.height / image.width}},[_c('img',{directives:[{name:"prefix",rawName:"v-prefix"},{name:"lazy",rawName:"v-lazy",value:(image.medium),expression:"image.medium"}],staticClass:"#gallery5__list-item-image fade-on-lazy-load object-fit-cover object-position-center object-fit-positioning"}),_c('span',{staticClass:"hide-on-small-down"},[_c('span',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#gallery5__list-item-details px-2 py-1 text-no-wrap"},[_vm._v("View Photo")])])])],1)],1)}),0)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }