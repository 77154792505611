var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.gallery)?_c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#gallery6"},[_c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],ref:"container",staticClass:"#gallery6__scroll-container overflow-hidden relative"},[_c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],ref:"track",staticClass:"#gallery6__scroll-track",style:({ transform: ("translateX(" + _vm.track_offset + "px)")})},[_c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],ref:"top_row",staticClass:"#gallery6__row flex"},_vm._l((_vm.gallery_images_top_row),function(image){return _c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],key:image.id,staticClass:"#gallery6__row-item flex-item-auto ma-1 relative overflow-hidden",style:({ 
                        width: ("calc(((100vh - 108px - max(calc(3rem + 72px), calc(1.5rem + 38px))) / 2) * " + (image.aspect_ratio) + ")"),
                    })},[_c('router-link',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#gallery6__list-item-link block w-100 h-100",attrs:{"to":{ 
                            name: 'image', 
                            params: Object.assign({}, _vm.$route.params, 
                                {image_caption_slug: image.caption_slug.substring(0,30) || ("image-" + (image.index+1)), 
                                image_id: image.id})
                        }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(image.medium),expression:"image.medium"}],staticClass:"fade-on-lazy-load h-100"}),_c('span',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#gallery6__list-item-details hide-on-small-down text-no-wrap"},[_vm._v("View Photo")])])],1)}),0),_c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],ref:"bottom_row",staticClass:"#gallery6__row flex"},_vm._l((_vm.gallery_images_bottom_row),function(image){return _c('div',{directives:[{name:"prefix",rawName:"v-prefix"}],key:image.id,staticClass:"#gallery6__row-item flex-item-auto ma-1 relative overflow-hidden",style:({ 
                        width: ("calc(((100vh - 108px - max(calc(3rem + 72px), calc(1.5rem + 38px))) / 2) * " + (image.aspect_ratio) + ")"),
                    })},[_c('router-link',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#gallery6__list-item-link block w-100 h-100",attrs:{"to":{ 
                            name: 'image', 
                            params: Object.assign({}, _vm.$route.params, 
                                {image_caption_slug: image.caption_slug || ("image-" + (image.index+1)), 
                                image_id: image.id})
                        }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(image.medium),expression:"image.medium"}],staticClass:"fade-on-lazy-load h-100"}),_c('span',{directives:[{name:"prefix",rawName:"v-prefix"}],staticClass:"#gallery6__list-item-details hide-on-small-down"},[_vm._v("View Photo")])])],1)}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }