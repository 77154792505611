<template>
    <div v-prefix v-if="gallery" class="#gallery5">
        <packery :sync="gallery.images" class="w-100">
            <div 
                v-for="(image, image_index) in gallery.images" 
                :key="image.id" 
                :class="{
                    'x-large-3': template_options.full_width,
                }" 
                class="x-small-6 large-4 pa-1"
            >
                <router-link 
                    v-prefix
                    :to="{ 
                        name: 'image', 
                        params: { 
                            ...$route.params, 
                            image_caption_slug: image.caption_slug.substring(0,30) || `image-${image_index+1}`, 
                            image_id: image.id 
                        }
                    }" 
                    class="#gallery5__list-item-link block relative overflow-hidden"
                >
                    <aspect-ratio :ratio="image.height / image.width">
                        <img v-prefix v-lazy="image.medium" class="#gallery5__list-item-image fade-on-lazy-load object-fit-cover object-position-center object-fit-positioning" />

                        <span class="hide-on-small-down">
                            <span v-prefix class="#gallery5__list-item-details px-2 py-1 text-no-wrap">View Photo</span>
                        </span>
                    </aspect-ratio>
                </router-link>
            </div>
        </packery>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            gallery: {
                type: Object,
                required: true,
                default: () => ({})
            }
        },

        data: () => ({

        }),

        computed: {
            ...mapState([
                'template_options',
            ]),
        }
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__gallery5 {
        margin: 0 $spacer*-1;

        &__list-item-link {
            background-color: $stroke-on-light;

            &::before, &::after {
                content: '';
                display: block;
                left: 0;
                position: absolute;
                top: 0;
                transition: .35s;
                width: 100%;
                z-index: 1;
            }

            &::before {
                background-color: $color-primary;
                height: 100%;
                opacity: 0;
            }

            &::after {
                background-color: $color-secondary;
                height: $spacer/3;
                transform-origin: left;
                transform: scaleX(0);
            }

            &:hover {
                &::before {
                    opacity: 0.8;
                }

                &::after {
                    transform: scaleX(1);
                }

                .#{$prefix}__gallery5__list-item-details {
                    opacity: 1;
                    transform: translate(-50%, -50%);
                    transition-delay: .2s;
                }
            }
        }

        &__list-item-details {
            border: 1px solid $text-on-primary;
            color: $text-on-primary;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -75%);
            transition: .35s;
            z-index: 2;
        }
    }
</style>