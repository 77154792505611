<template>
    <div v-if="gallery" v-prefix class="#gallery1 flex flex-wrap">
        <packery :sync="gallery_images" class="w-100">
            <template v-for="(image, image_index) in gallery_images" >
                <div :key="image.id" :class="image.layout_classes" class="pa-1">
                    <router-link 
                        v-prefix
                        :to="{ 
                            name: 'image', 
                            params: { 
                                ...$route.params, 
                                image_caption_slug: image.caption_slug.substring(0,30) || `image-${image_index+1}`, 
                                image_id: image.id 
                            }
                        }" 
                        class="#gallery1__list-item-link block w-100 relative overflow-hidden text-center text-decoration-none"
                    >
                        <aspect-ratio :ratio="image.layout_aspect_ratio" class="flex flex-align-center flex-justify-center">
                            <img v-prefix v-lazy="image.layout_src" class="#gallery1__list-item-image fade-on-lazy-load object-fit-cover object-position-center object-fit-positioning" />

                            <span class="hide-on-small-down">
                                <span v-prefix class="#gallery1__list-item-details inline-block py-1 px-2 relative text-no-wrap type-base">View Photo</span>
                            </span>
                        </aspect-ratio>
                    </router-link>
                </div>

                <div v-if="image_index > 0 && (image_index+1) % 9 === 0" :key="`gallery_image_ad_${image_index}`" class="x-small-12 columns pa-1">
                    <dfp-ad v-if="repeated_ad_data || display_dummy_ads" :location="repeated_ad_data.location" name="galleries-repeated-1" />
                </div>
            </template>
        </packery>
    </div>
</template>

<script>
    import DfpAd from '@/components/Ads/DfpAd'
    import { mapState } from 'vuex'

    export default {
        props: {
            gallery: {
                type: Object,
                required: true,
                default: () => ({})
            }
        },

        components: {
            DfpAd,
        },

        data: () => ({
            items_per_row: 0,
        }),

        computed: {
            ...mapState([
                'template_options',
                'repeated_ad_data',
                'display_dummy_ads',
            ]),

            gallery_images() {
                if (!this.gallery || !this.gallery.images) {
                    return []
                }

                return this.gallery.images.map((image, index) => {
                    image.layout_aspect_ratio = 1;
                    image.layout_src = image.medium;
                    image.layout_classes = 'x-small-6 large-3';

                    if (index % 18 === 0 || (index - 11) % 18  === 0) {
                        image.layout_classes = 'x-small-12 large-6';
                        image.layout_src = image.large;
                    }

                    return image;
                });
            },
        },

        methods: {
            create_item_classes(image, image_index) {
                if (image_index === 0) {
                    return 'x-small-12';
                }

                return 'x-small-6 large-4';
            },

            set_items_per_row() {
                if (window.matchMedia('(min-width: 1024px)').matches && this.template_options.full_width) {
                    this.items_per_row = 4;
                    return;
                }
                else if (window.matchMedia('(min-width: 768px)').matches) {
                    this.items_per_row = 3;
                    return;
                }

                this.items_per_row = 2;
            }
        },

        created() {
            window.addEventListener('resize', this.set_items_per_row);

            window.dispatchEvent(new Event('resize'));
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.set_items_per_row, false);
        }
    }
</script>

<style lang="scss" scoped>
    .#{$prefix}__gallery1 {
        margin: 0 $spacer*-1;

        &__list-item-link {
            background-color: $stroke-on-light;
            
            &::before, &::after {
                content: '';
                display: block;
                left: 0;
                position: absolute;
                top: 0;
                transition: .35s;
                width: 100%;
                z-index: 1;
            }

            &::before {
                background-color: $color-primary;
                height: 100%;
                opacity: 0;
            }

            &::after {
                background-color: $color-secondary;
                height: $spacer/3;
                transform-origin: left;
                transform: scaleX(0);
            }

            &:hover {
                &::before {
                    opacity: 0.8;
                }

                &::after {
                    transform: scaleX(1);
                }

                .#{$prefix}__gallery1__list-item-details {
                    opacity: 1;
                    transform: translateY(0);
                    transition-delay: .2s;
                }
            }
        }

        &__list-item-details {
            border: 1px solid $text-on-primary;
            color: $text-on-primary;
            opacity: 0;
            transform: translateY(-50%);
            transition: .35s;
            z-index: 2;
        }
    }
</style>